// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("/opt/build/repo/src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-philosophy-js": () => import("/opt/build/repo/src/templates/philosophy.js" /* webpackChunkName: "component---src-templates-philosophy-js" */),
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-team-js": () => import("/opt/build/repo/src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-diabetic-education-consultations-js": () => import("/opt/build/repo/src/templates/diabetic-education-consultations.js" /* webpackChunkName: "component---src-templates-diabetic-education-consultations-js" */),
  "component---src-templates-fees-js": () => import("/opt/build/repo/src/templates/fees.js" /* webpackChunkName: "component---src-templates-fees-js" */),
  "component---src-templates-individual-consultations-js": () => import("/opt/build/repo/src/templates/individual-consultations.js" /* webpackChunkName: "component---src-templates-individual-consultations-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

